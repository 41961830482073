import { useState, FC, useEffect, useCallback } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { FormFieldValidator, FormValidatorDetails } from "../../../utils/formFieldValidator";
import { IFormEventData, sendAnalyticsEvent } from "../../../utils/analitics";
import FormField from "./form-field";

interface IFormTextAreaField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  errorMessage?: string;
  label: string;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  placeholder?: string;
  formEventData?: IFormEventData;
}

const FormTextAreaField: FC<IFormTextAreaField> = ({
  required,
  formValidator,
  fieldName,
  errorMessage,
  className,
  label,
  maxLength,
  disabled,
  placeholder,
  formEventData,
}) => {
  const value = formValidator.getFieldValue(fieldName);
  const [curValue, setValue] = useState(value);
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    setValue(value);
  }, [value]);

  const validateCallback = useCallback(
    (e: Event) => {
      if (required) {
        if (!curValue) {
          (e as CustomEvent<FormValidatorDetails>).detail.validationFailed = true;
          setValidated(false);
          return;
        }
      }

      setValidated(true);
      formValidator.setFieldValue(fieldName, curValue);
    },
    [curValue, fieldName, formValidator, required],
  );

  useEffect(() => {
    formValidator.addEventListener("validated", validateCallback);
    return () => {
      formValidator.removeEventListener("validated", validateCallback);
    };
  }, [formValidator, validateCallback]);

  const handleChange = useCallback(
    (e: { target: { value: any } }) => {
      setValue(e.target.value);
      formValidator.setFieldValue(fieldName, e.target.value);
      if (!validated) {
        setValidated(true);
      }
    },
    [formValidator, fieldName, validated],
  );

  return (
    <FormField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      validated={validated}
      errorMessage={errorMessage}
    >
      <textarea
        className={cx(styles.input, styles.textarea, {
          [styles.error]: validated !== undefined && !validated,
        })}
        name={fieldName}
        id={fieldName || ""}
        value={curValue}
        placeholder={placeholder}
        required={required || false}
        onChange={handleChange}
        onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
        disabled={disabled}
        maxLength={maxLength}
      />
    </FormField>
  );
};

export default FormTextAreaField;
