import { FC } from "react";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import FormTextField from "./text-field";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface IFormEmailField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  label: string;
  className: string;
  disabled?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

const FormEmailField: FC<IFormEmailField> = ({
  required,
  formValidator,
  fieldName,
  className,
  label,
  disabled,
  formEventData,
  translations,
}) => {
  const t = getTranslationModule(translations, "common");
  return (
    <FormTextField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      errorMessage={t("invalid_format")}
      formValidator={formValidator}
      disabled={disabled}
      inputType={"email"}
      maxLength={64}
      formEventData={formEventData}
    />
  );
};

export default FormEmailField;
