import { FC } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { DropDownOption } from "../../../types/dropdown";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import FormTextField from "../form-fields/text-field";
import FormDropdownField from "../form-fields/dropdown-field";
import ContactPhoneField from "../form-fields/contact-phone";
import ContactEmailField from "../form-fields/contact-email";
import FormTextAreaField from "../form-fields/textarea-field";
import FormZipCodeField from "../form-fields/zip-code-field";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface IContactForm {
  className?: string;
  title?: string;
  formValidator: FormFieldValidator;
  comment?: boolean;
  disabled?: boolean;
  contactOptions: DropDownOption[];
  zipCode?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

const ContactForm: FC<IContactForm> = ({
  className,
  title,
  formValidator,
  comment,
  disabled,
  contactOptions,
  zipCode,
  formEventData,
  translations,
}) => {
  const t = getTranslationModule(translations, "common");

  return (
    <div className={cx(styles.container, className)}>
      {title && <h4 className={styles.title}>{title}</h4>}
      <form name={"contactForm"} className={styles.form}>
        <FormTextField
          formValidator={formValidator}
          fieldName={"firstName"}
          label={t("first_name")}
          inputType="text"
          errorMessage={t("first_name_error")}
          className={styles.input}
          required={true}
          disabled={disabled}
          maxLength={64}
          formEventData={formEventData}
        />
        <FormTextField
          formValidator={formValidator}
          fieldName={"lastName"}
          label={t("last_name")}
          inputType="text"
          errorMessage={t("last_name_error")}
          className={styles.input}
          required={true}
          disabled={disabled}
          maxLength={64}
          formEventData={formEventData}
        />
        <FormDropdownField
          label={t("prefered_contact")}
          className={styles.dropdown}
          options={contactOptions}
          disabled={disabled}
          formValidator={formValidator}
          fieldName="contactType"
          required={true}
          formEventData={formEventData}
        />
        <ContactPhoneField
          formValidator={formValidator}
          fieldName={"phone"}
          label={t("phone")}
          className={styles.input}
          disabled={disabled}
          formEventData={formEventData}
          translations={translations}
        />
        <ContactEmailField
          formValidator={formValidator}
          fieldName={"email"}
          label={t("email_address")}
          className={styles.input}
          disabled={disabled}
          formEventData={formEventData}
          translations={translations}
        />
        {comment && (
          <FormTextAreaField
            className={styles.textarea}
            label={t("comments")}
            formValidator={formValidator}
            fieldName="comments"
            disabled={disabled}
            required={false}
            formEventData={formEventData}
          />
        )}
        {zipCode && (
          <FormZipCodeField
            formValidator={formValidator}
            fieldName={"zipCode"}
            label={t("zip_code")}
            className={styles.input}
            required={true}
            disabled={disabled}
            formEventData={formEventData}
            translations={translations}
          />
        )}
      </form>
    </div>
  );
};

export default ContactForm;
