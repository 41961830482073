export function validateText(text: string): boolean {
  let re = /(^[a-zA-Z]{1,}[a-zA-Z ]*)$/;
  return re.test(text);
}

export function validateString(text: string): boolean {
  let re = /^[a-zA-Z0-9\s.,/'-]+$/;

  return re.test(text);
}

export function validateZipCode(text: string): boolean {
  let re = /^[0-9]{5}$/;
  return re.test(text);
}

export function validateNumber(value: number | string, includeZero?: boolean): boolean {
  let numberToValidate = value.toString();
  let valueAsNumber = typeof value === "number" ? value : parseInt(value);
  if (numberToValidate.length > 0) {
    return includeZero ? valueAsNumber >= 0 : valueAsNumber > 0;
  } else {
    return false;
  }
}

export function validateYear(year: number): boolean {
  let currentYear = new Date().getFullYear();
  return year > 1990 && year <= currentYear;
}

export function validateMileage(mileage: number): boolean {
  return mileage > 0 ? true : false;
}

export function phoneFormat(input: string): string {
  // Strip all characters from the input except digits
  input = input.replace(/\D/g, "");

  // Trim the remaining input to ten characters, to preserve phone number format
  input = input.substring(0, 10);

  // Based upon the length of the string, we add formatting as necessary
  let size = input.length;
  if (size == 0) {
    return input;
  } else if (size < 4) {
    input = "(" + input;
  } else if (size < 7) {
    input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
  } else {
    input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6) + "-" + input.substring(6, 10);
  }
  return input;
}

export function socialNumberFormat(input: string): string {
  // Strip all characters from the input except digits
  input = input.replace(/\D/g, "");

  // Trim the remaining input to nine characters, to preserve social number format
  input = input.substring(0, 9);

  // Based upon the length of the string, we add formatting as necessary
  let size = input.length;
  if (size == 0) {
    return input;
  } else if (size >= 1 && size <= 3) {
    return input;
  } else if (size > 3 && size <= 5) {
    input = input.substring(0, 3) + "-" + input.substring(3, 5);
  } else {
    input = input.substring(0, 3) + "-" + input.substring(3, 5) + "-" + input.substring(5, 9);
  }
  return input;
}
