export class FormValidatorDetails {
  validationFailed = false;
}

export class FormFieldValidator implements EventTarget {
  private listeners: { [eventName: string]: ((event: Event) => void)[] } = {};

  public formData: any;
  constructor(formData: any) {
    this.formData = formData;
  }

  // EventTarget interface methods
  addEventListener(eventName: string, callback: (event: Event) => void): void {
    if (!this.listeners[eventName]) {
      this.listeners[eventName] = [];
    }
    this.listeners[eventName].push(callback);
  }

  removeEventListener(eventName: string, callback: (event: Event) => void): void {
    if (this.listeners[eventName]) {
      this.listeners[eventName] = this.listeners[eventName].filter((cb) => cb !== callback);
    }
  }

  dispatchEvent(event: Event): boolean {
    if (this.listeners[event.type]) {
      this.listeners[event.type].forEach((cb) => cb(event));
      return true;
    }
    return false;
  }

  // FormFieldValidator specific methods
  validate(): boolean {
    const validatedEvent = new CustomEvent<FormValidatorDetails>("validated", { detail: new FormValidatorDetails() });
    this.dispatchEvent(validatedEvent);
    return !validatedEvent.detail.validationFailed;
  }

  update(): void {
    const updatedEvent = new CustomEvent("updated");
    this.dispatchEvent(updatedEvent);
  }

  getFieldValue(fieldName: string): any {
    const fields = fieldName.split(".");
    let current = this.formData;
    for (let i = 0; i < fields.length - 1; i++) {
      if (current[fields[i]] === undefined) {
        return undefined;
      }
      current = current[fields[i]];
    }
    return current[fields[fields.length - 1]];
  }

  setFieldValue(fieldName: string, value: any) {
    const fields = fieldName.split(".");
    let current = this.formData;
    for (let i = 0; i < fields.length - 1; i++) {
      if (current[fields[i]] === undefined) {
        current[fields[i]] = {};
      }
      current = current[fields[i]];
    }
    current[fields[fields.length - 1]] = value;
    this.update();
  }
}
