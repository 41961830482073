import { FC, ReactNode } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import ErrorIcon from "../../icons/error-icon";

interface IFormField {
  required: boolean;
  fieldName: string;
  errorMessage?: string;
  label: string;
  className?: string;
  validated: boolean;
  hideErrorIcon?: boolean;
  children: ReactNode[] | ReactNode;
}

const FormField: FC<IFormField> = ({
  required,
  fieldName,
  errorMessage,
  className,
  label,
  validated,
  children,
  hideErrorIcon,
}) => {
  return (
    <div className={cx(styles.container, className)}>
      {label && (
        <label htmlFor={fieldName} className={styles.label}>
          {required ? label + " *" : label}
        </label>
      )}
      <div className={styles.inputContainer}>
        {children}
        {!validated && hideErrorIcon !== true && <ErrorIcon className={styles.errorIcon} />}
        {!validated && <p className={styles.errorMessage}>{errorMessage}</p>}
      </div>
    </div>
  );
};

export default FormField;
