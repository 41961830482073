import { FC, useCallback } from "react";
import FormTextField from "./text-field";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface IFormZipCodeField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  label: string;
  className: string;
  disabled?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

const FormZipCodeField: FC<IFormZipCodeField> = ({
  required,
  formValidator,
  fieldName,
  className,
  label,
  disabled,
  formEventData,
  translations,
}) => {
  const t = getTranslationModule(translations, "common");
  const zipCodeFormatter = useCallback((input: string) => {
    return input.slice(0, 5);
  }, []);

  return (
    <FormTextField
      formValidator={formValidator}
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      errorMessage={t("invalid_format")}
      disabled={disabled}
      inputType="number"
      formater={zipCodeFormatter}
      maxLength={5}
      formEventData={formEventData}
    />
  );
};

export default FormZipCodeField;
