import { useCallback, useEffect, useState, useRef, FC } from "react";
import styles from "./styles.module.scss";
import cx from "classnames";
import { DropDownOption } from "../../../types/dropdown";
import ArrowIcon from "../../icons/arrow-icon";

interface ICustomDropdown {
  options: DropDownOption[];
  selectedOption?: any;
  label?: string;
  className?: string;
  onChanged: (value: any, tag?: string) => void;
  tag?: string;
  disabled?: boolean;
  searchCar?: boolean;
  searchCarExtended?: boolean;
  error?: boolean;
  textOnNonExistingValue?: string;
}

const CustomDropdown: FC<ICustomDropdown> = ({
  options,
  label,
  className,
  onChanged,
  tag,
  disabled,
  searchCar,
  searchCarExtended,
  selectedOption,
  error,
  textOnNonExistingValue,
}) => {
  const initialSelectedOption = selectedOption
    ? options?.find((item) => item.value === selectedOption)
    : textOnNonExistingValue
      ? undefined
      : options[0];
  const [currentOption, setCurrentOption] = useState<DropDownOption | undefined>(initialSelectedOption);
  const [isOpened, setIsOpened] = useState(false);
  const [reversedDirection, setReversedDirection] = useState<boolean | undefined>(undefined);
  const ref = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState("");

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!ref?.current?.contains(e.target)) {
        setIsOpened(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    setCurrentOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleChange = (optionItem: any) => {
    const newValue = optionItem.value;
    const option = options.find((item) => item.value === newValue);

    setCurrentOption(option!);
    if (onChanged) {
      onChanged(newValue, tag);
    }
  };

  const setOpenDirection = useCallback(() => {
    const reference = ref?.current;
    const windowHeight = window.innerHeight;
    const vh = windowHeight / 100;
    const heightToTop = reference?.getBoundingClientRect() ? reference?.getBoundingClientRect().top - 130 : 0;
    const heightToBottom = windowHeight - (reference?.getBoundingClientRect().bottom || 0);

    if (heightToTop < heightToBottom) {
      setReversedDirection(false);
      setMaxHeight(`${Math.round(heightToBottom / vh - 7)}vh`);
    } else {
      setReversedDirection(true);
      setMaxHeight(`${Math.round(heightToTop / vh - 7)}vh`);
    }
  }, []);

  return (
    <div
      className={cx(styles.content, {
        [styles.searchCar]: searchCar,
        [styles.searchCarExtended]: searchCarExtended,
        [styles.reversed]: reversedDirection,
      })}
    >
      <div
        className={cx(styles.container, className, {
          [styles.isOpened]: isOpened,
          [styles.disabled]: disabled,
        })}
        ref={ref}
      >
        <div
          className={cx(styles.option, styles.optionSelected, {
            [styles.error]: error,
          })}
          onClick={() => {
            setOpenDirection();
            setIsOpened(!isOpened);
          }}
        >
          <span className={styles.optionText}>{currentOption?.text || textOnNonExistingValue}</span>
          <ArrowIcon />
        </div>
        {isOpened && (
          <ul className={styles.list} style={{ maxHeight: maxHeight }}>
            {options &&
              options.length > 0 &&
              options.map((item, index: number) => (
                <li
                  className={styles.option}
                  key={index}
                  onClick={() => {
                    handleChange(item);
                    setIsOpened(false);
                  }}
                >
                  <span className={styles.optionText}>{item.text}</span>
                </li>
              ))}
          </ul>
        )}
      </div>
      {label && <span className={styles.label}>{label}</span>}
    </div>
  );
};

export default CustomDropdown;
