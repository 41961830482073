import { FC, useCallback, useEffect, useState } from "react";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import FormEmailField from "./email-field";
import { ContactType } from "../../../types/lead";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";

interface IContactEmailField {
  formValidator: FormFieldValidator;
  fieldName: string;
  label: string;
  className: string;
  disabled?: boolean;
  requiredFromProps?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

const ContactEmailField: FC<IContactEmailField> = ({
  formValidator,
  fieldName,
  className,
  label,
  disabled,
  requiredFromProps,
  formEventData,
  translations,
}) => {
  const [required, setRequired] = useState(
    requiredFromProps ? requiredFromProps : formValidator.getFieldValue("contactType") === ContactType.EMAIL,
  );

  const requiredFieldCheck = useCallback(() => {
    if (formValidator.getFieldValue("contactType") === ContactType.EMAIL || requiredFromProps) {
      if (!required) {
        setRequired(true);
      }
    } else {
      if (required) {
        setRequired(false);
      }
    }
  }, [formValidator, required, requiredFromProps]);

  useEffect(() => {
    requiredFieldCheck();
  }, [requiredFieldCheck]);

  useEffect(() => {
    formValidator.addEventListener("updated", requiredFieldCheck);
  }, [formValidator, requiredFieldCheck]);

  return (
    <FormEmailField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      formValidator={formValidator}
      disabled={disabled}
      formEventData={formEventData}
      translations={translations}
    />
  );
};

export default ContactEmailField;
