import { FC, useEffect, useCallback, useState } from "react";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import { ContactType } from "../../../types/lead";
import FormPhoneField from "./phone-field";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";

interface IContactPhoneField {
  formValidator: FormFieldValidator;
  fieldName: string;
  label: string;
  className?: string;
  disabled?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

function isRequired(value: ContactType): boolean {
  return value === ContactType.MESSAGE || value === ContactType.PHONE;
}

const ContactPhoneField: FC<IContactPhoneField> = ({
  formValidator,
  fieldName,
  className,
  label,
  disabled,
  formEventData,
  translations,
}) => {
  const [required, setRequired] = useState(isRequired(formValidator.getFieldValue("contactType")));

  const requiredFieldCheck = useCallback(() => {
    if (isRequired(formValidator.getFieldValue("contactType"))) {
      if (!required) {
        setRequired(true);
      }
    } else {
      if (required) {
        setRequired(false);
      }
    }
  }, [formValidator, required]);

  useEffect(() => {
    requiredFieldCheck();
  }, [requiredFieldCheck]);

  useEffect(() => {
    formValidator.addEventListener("updated", requiredFieldCheck);
  }, [formValidator, requiredFieldCheck]);

  return (
    <FormPhoneField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      formValidator={formValidator}
      disabled={disabled}
      formEventData={formEventData}
      translations={translations}
    />
  );
};

export default ContactPhoneField;
