import { IEmploymentPositionForm } from "./employment";
import { IFinanceContactInfoForm, IFinanceEmploymentInfo, IFinanceResidenceInfoForm } from "./finance";
import { IOrderPartAdditionalInfo, IOrderPartVehicleForm, OrderPartInfo } from "./part";
import { Address } from "./site";
import { IValueYourTradeResidenceForm, IValueYourTradeVehicleForm } from "./valueYourTrade";
import { VehicleType } from "./vehicle";

export enum ContactType {
  PHONE = "phone",
  MESSAGE = "message",
  EMAIL = "email",
}

export enum LeadSection {
  SALES = "Sales",
  SERVICE = "Service",
  PARTS = "Parts",
  FINANCE = "Finance",
}

export interface IContactForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface ContactFormData extends IContactForm {
  contactType: ContactType;
  comments?: string;
  zipCode?: string | number;
}

export interface LeadData extends ContactFormData {
  source: string;
  providerName?: string;
  id?: string;
  createdDate?: string;
  siteConfigId?: string;
  siteInstanceId?: string;
}

export interface CustomLeadData {
  source: string;
  leadParams: any;
  siteConfigId?: string;
  siteInstanceId?: string;
}

export interface ServiceLeadData extends LeadData {
  serviceDescription?: string;
  year?: number;
  make?: string;
  model?: string;
  mileage?: number;
  vin?: string;
  dropOffDescription?: string;
  preferredDate?: string;
  preferredTime?: string;
}

export enum VehicleInterestType {
  BUY = "buy",
  TEST_DRIVE = "test-drive",
  LEASE = "lease",
}

export interface VehicleLeadData extends LeadData {
  vin?: string;
  stock?: string;
  year?: number;
  make?: string;
  model?: string;
  trim?: string;
  type?: VehicleType;
  preferedDate?: string;
  preferedTime?: string;
  interest?: VehicleInterestType;
  price?: number;
  msrp?: number;
}

export interface ScheduleDateTimeData {
  preferedDate: string;
  preferedTime: string;
}

export interface FinanceLead extends IFinanceContactInfoForm, IFinanceResidenceInfoForm, IFinanceEmploymentInfo {
  source: string;
  salesPerson?: string;
  comments?: string;
  siteConfigId?: string;
  siteInstanceId?: string;
}

export interface EmploymentAddLead extends IContactForm, IEmploymentPositionForm {
  source?: string;
  address: Address;
  siteConfigId?: string;
  siteInstanceId?: string;

  comments: string;
}

export interface OrderPartsLead extends IContactForm, IOrderPartVehicleForm, IOrderPartAdditionalInfo {
  source?: string;
  parts: OrderPartInfo[];
  siteConfigId?: string;
  siteInstanceId?: string;
}

export interface ValueYourTradeAddLead extends IContactForm, IValueYourTradeVehicleForm, IValueYourTradeResidenceForm {
  source?: string;
  comments?: string;
  siteConfigId?: string;
  siteInstanceId?: string;
}
