import { FC, useCallback } from "react";
import { FormFieldValidator } from "../../../utils/formFieldValidator";
import FormTextField from "./text-field";
import { phoneFormat } from "../../../utils/validators";
import { IFormEventData } from "../../../utils/analitics";
import { Translations } from "../../../utils/translations";
import { getTranslationModule } from "../../../utils/translations";

interface IFormPhoneField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  label: string;
  className?: string;
  disabled?: boolean;
  formEventData?: IFormEventData;
  translations: Translations;
}

const FormPhoneField: FC<IFormPhoneField> = ({
  required,
  formValidator,
  fieldName,
  className,
  label,
  disabled,
  formEventData,
  translations,
}) => {
  const t = getTranslationModule(translations, "common");
  const phoneRegExp = /\(?\d{3}\)?-? *\d{3}-? *-?\d{4}/;
  const phoneParser = useCallback((input: string) => {
    return input.replace(/\D/g, "").substring(0, 10);
  }, []);
  return (
    <FormTextField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      errorMessage={t("invalid_format")}
      formValidator={formValidator}
      disabled={disabled}
      placeholder={"(000) 111 - 2222"}
      inputType={"tel"}
      formater={phoneFormat}
      parser={phoneParser}
      regex={phoneRegExp}
      maxLength={14}
      formEventData={formEventData}
    />
  );
};

export default FormPhoneField;
