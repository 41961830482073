import { useState, FC, useEffect, useCallback, HTMLInputTypeAttribute } from "react";
import cx from "classnames";
import styles from "./styles.module.scss";
import { FormFieldValidator, FormValidatorDetails } from "../../../utils/formFieldValidator";
import FormField from "./form-field";
import { IFormEventData, sendAnalyticsEvent } from "../../../utils/analitics";

interface IFormTextField {
  required: boolean;
  formValidator: FormFieldValidator;
  fieldName: string;
  placeholder?: string;
  errorMessage?: string;
  label: string;
  inputType?: HTMLInputTypeAttribute;
  className?: string;
  maxLength?: number;
  disabled?: boolean;
  regex?: RegExp;
  formater?: (input: any) => string;
  parser?: (input: string) => any;
  formEventData?: IFormEventData;
  onChange?: (value: string) => void;
}

const FormTextField: FC<IFormTextField> = ({
  required,
  formValidator,
  fieldName,
  placeholder,
  inputType,
  errorMessage,
  className,
  label,
  maxLength,
  disabled,
  regex,
  formater,
  parser,
  formEventData,
  onChange,
}) => {
  const value = formValidator.getFieldValue(fieldName);
  const [curValue, setValue] = useState(formater ? formater(value) : value);
  const [validated, setValidated] = useState(true);

  useEffect(() => {
    setValue(formater ? formater(value) : value);
  }, [formater, value]);

  const validateCallback = useCallback(
    (e: Event) => {
      if (required) {
        if (!curValue) {
          (e as CustomEvent<FormValidatorDetails>).detail.validationFailed = true;
          setValidated(false);
          return;
        }
      }

      if (curValue && regex) {
        if (!regex.test(curValue)) {
          (e as CustomEvent<FormValidatorDetails>).detail.validationFailed = true;
          setValidated(false);
          return;
        }
      }
      setValidated(true);
      formValidator.setFieldValue(fieldName, parser ? parser(curValue) : curValue);
    },
    [curValue, fieldName, formValidator, parser, regex, required],
  );

  useEffect(() => {
    formValidator.addEventListener("validated", validateCallback);
    return () => {
      formValidator.removeEventListener("validated", validateCallback);
    };
  }, [formValidator, validateCallback]);

  const onChangeHandler = useCallback(
    (e: { target: { value: string } }) => {
      const newvalue = parser ? parser(e.target.value) : e.target.value;
      setValue(formater ? formater(newvalue) : newvalue);
      formValidator.setFieldValue(fieldName, formater ? formater(newvalue) : newvalue);
      if (!validated) {
        setValidated(true);
      }
      if (onChange) {
        onChange(formater ? formater(newvalue) : newvalue);
      }
    },
    [formValidator, fieldName, formater, onChange, parser, validated],
  );

  return (
    <FormField
      label={label}
      required={required}
      fieldName={fieldName}
      className={className}
      validated={validated}
      errorMessage={errorMessage}
    >
      <input
        id={fieldName}
        className={cx(styles.input, { [styles.error]: !validated })}
        name={fieldName}
        placeholder={placeholder}
        type={inputType}
        value={curValue === undefined ? "" : curValue}
        required={required}
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChangeHandler}
        onBlur={() => sendAnalyticsEvent("asc_form_engagement", { comm_status: "engage", ...formEventData })}
      />
    </FormField>
  );
};

export default FormTextField;
